import React from 'react'

class TeamPage extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.location.href = '/article/dashboard-50-team-admin/';
    }
  }

  render() {
    return (<div>

    </div>)
  }

}

export default TeamPage
